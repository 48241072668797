<template>
	<transition name="notification" @after-leave="this.afterLeave()">
		<div v-show="show" ref="root" class="alert alert-dismissible show mb-1" :class="this.typeClass">
			{{this.message}}
			<button type="button" class="btn-close" @click="this.destroy()"></button>
		</div>
	</transition>
</template>

<script>
import { render } from 'vue';
import { removeElement } from './helper.js';

export default {
	name: "NotificationComponent",
	props: { message: String, type: String, timeout: Number },
	data: () => {
		return {
			typeClass: null,
			show: false,
		}
	},
	mounted() {
		switch (this.type) {
			case 'error':
				this.typeClass = 'alert-danger';
				break;
			case 'success':
				this.typeClass = 'alert-success';
				break;
			case 'warning':
				this.typeClass = 'alert-warning';
				break;
			case 'primary':
				this.typeClass = 'alert-primary';
				break;
			case 'info':
				this.typeClass = 'alert-info';
				break;
		}

		this.show = true;

		if (this.timeout !== undefined) {
			setTimeout(() => {
				this.destroy();
			}, this.timeout);
		}
	},
	methods: {
		destroy() {
			this.show = false;
		},
		afterLeave() {
			const wrapper = this.$refs.root;
			render(null, wrapper);
			removeElement(wrapper);
		}
	},
}
</script>

<style scoped>
.notification-enter-active,
.notification-leave-active {
	transition: all 0.25s ease;
}
.notification-enter-from,
.notification-leave-to {
	opacity: 0;
}
</style>
