<template>
	<div>
		<Box>
			<template #title>
				OLT lista
			</template>
			<template #content>
				<template v-if="oltList">
					<v-table :rows="oltList" :cols="oltCols" :filename="filename"></v-table>
				</template>
				<template v-else>
					<Loader></Loader>
				</template>
			</template>
		</Box>
	</div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { defineAsyncComponent } from "vue";

const Box = defineAsyncComponent(() => import("@/components/box.vue"));
const Loader = defineAsyncComponent(() => import("@/components/loader.vue"));
const Table = defineAsyncComponent(() => import("@/components/table.vue"));

export default {
	name: "OltList",
	components: { Box, Loader, "v-table": Table },
	data: () => {
		return {
			oltList: null,

			oltCols: [
				{ data: "name", title: "Name" },
				{ data: "type", title: "Type" },
				{ data: "hostname", title: "Hostname" },
				{ data: "sysDescr", title: "sysDescr" },
				{ data: "sysUptime", title: "sysUptime", render: function(data) { return (data != null ? parseInt(moment.utc(data * 1000).format("DDD")) - 1 + "nap " + moment.utc(data * 1000).format("HH:mm:ss") : "-"); } },
			],

			filename: null,
		};
	},
	mounted() {
		let filenameArray = ["SparK", 'oltlista'];

		filenameArray.push(moment().format("YYYY-MM-DD_hh-mm-ss"));

		this.filename = filenameArray.join("_");

		axios
			.get(this.$root.$data.apiUrl + this.$root.$data.apiPath + "olt/")
			.then((response) => {
				this.oltList = response.data.objects;
			})
			.catch((error) => {
				console.log(error);
			});
	},
};
</script>

<style scoped>
.monitor {
	color: #eab308;
}

.metrics {
	color: #22c55e;
}

.monitor.metrics {
	color: #3b82f6;
}
</style>
