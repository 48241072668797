import ConfirmDialogComponent from './Component.vue'
import { useConfirmDialog } from "./api"

const ConfirmDialogPlugin = {
    install: (app, options = {}) => {
        let instance = useConfirmDialog(options);
        app.config.globalProperties.$confirmDialog = instance
        app.provide('$confirmDialog', instance)
    }
}

export default ConfirmDialogPlugin;
export { useConfirmDialog, ConfirmDialogPlugin, ConfirmDialogComponent }
