<template>
	<Box class="mb-3" v-if="searchField">
		<template #title>
			ONU Keresés
		</template>

		<template #content>
			<div class="row g-3" @keypress.enter="getOnuList()">
				<div class="col-6">
					<div class="input-group mb-3">
						<span class="input-group-text">ONU azonosító</span>
						<input type="text" class="form-control" v-model="nt_id">
					</div>
				</div>
				<div class="col-12 text-end">
					<button type="button" class="btn btn-primary" @click="getOnuList()" :disabled="this.loading">
						<span class="spinner-border spinner-border-sm" v-if="loading"></span>
						Keresés
					</button>
				</div>
			</div>
		</template>
	</Box>
	<Box class="full-width">

		<template #title>
			ONU lista
			<template v-if="this.$root.$data.selectedOlt && olt_id !== null">
				- {{ this.$root.$data.selectedOlt.name }}
			</template>

			<template v-if="onuList">
				-
				online: {{ online }}
				offline: {{ offline }}
				unknown: {{ unknown }}
				egyéb: {{ onuList.length - (online + offline + unknown) }}
				összes: {{ onuList.length }}
			</template>
		</template>

		<template #content>
			<template v-if="onuList">
				<button class="btn btn-success my-3" @click="showMap = true"><i class="bi bi-geo-alt-fill"></i> Megjelenítés térképen</button>
				<v-table v-if="onuCols" :rows="onuList" :cols="onuCols" :selectRow="selectOnu" :filename="filename"></v-table>
			</template>

			<template v-else-if="loading">
				<Loader></Loader>
			</template>
		</template>
	</Box>
	<OnuModal v-if="selectedOnu" :onu="selectedOnu" :close="closeModal"></OnuModal>
	<Map v-if="showMap && onuList" :onuList="onuList" :title="this.$root.$data.selectedOlt && olt_id !== null ? this.$root.$data.selectedOlt.name : undefined" :close="closeMap"></Map>
</template>

<script>
import axios from "axios";
import { watch } from "@vue/runtime-core";
import { defineAsyncComponent } from "vue";

import $ from "jquery";
import moment from "moment";

const Box = defineAsyncComponent(() => import("@/components/box.vue"));
const Loader = defineAsyncComponent(() => import("@/components/loader.vue"));
const Table = defineAsyncComponent(() => import("@/components/table.vue"));
const OnuModal = defineAsyncComponent(() => import("@/components/onu_modal.vue"));

const Map = defineAsyncComponent(() => import("@/components/map.vue"));

export default {
	name: "OnuList",
	components: { Box, "v-table": Table, Loader, OnuModal, Map },
	data: () => {
		return {
			controller: null,

			onuList: null,
			onuCols: [
				{ data: "nt_id", title: "ID", defaultContent: "-" },
				{ data: "index", type: "index", title: "Index", defaultContent: "-" },
				{ data: "status", title: "Status", defaultContent: "-", createdCell: function (cell, cellData) { $(cell).addClass({ "online": "bg-success text-white", "offline": "bg-danger text-white", "other": "bg-secondary text-white" }[cellData]) } },
				{ data: "is_korlatozva", title: '<i class="bi bi-person-fill-lock"></i>', defaultContent: "-", render: function (data, type, row) { return (row.is_szuneteltetve ? "SZ" : data ? "K" : "") }, createdCell: function (cell, cellData, row) { $(cell).addClass(cellData || row.is_szuneteltetve ? "bg-danger text-white" : "") } },
				{ data: "distance", title: "Distance", defaultContent: "-" },
				{ data: "temperature", title: "Temperature", defaultContent: "-", render: function (data) { return data ? data.toFixed(0) : null; } },
				{ data: "rx", title: "RX", defaultContent: "-", render: function (data) { return data ? data.toFixed(1) : null; } },
				{ data: "address", title: "Vételi hely", defaultContent: "-" },
				{ data: "name", title: "Ügyfél név", defaultContent: "-" },
				{ data: "internet_subscriber", title: '<i class="bi bi-ethernet"></i>', defaultContent: "-" },
				{ data: "tv_subscriber", title: '<i class="bi bi-tv-fill"></i>', defaultContent: "-" },
				{ data: "telephone_subscriber", title: '<i class="bi bi-telephone-fill"></i>', defaultContent: "-" },
			],

			loading: false,

			port: null,
			olt_id: null,
			nt_id: null,

			selectedOnu: null,

			searchField: false,

			online: 0,
			offline: 0,
			unknown: 0,

			filename: null,

			showMap: false,
		};
	},
	mounted() {
		this.controller = new AbortController();
		this.searchField = this.$route.meta.searchField;

		if (!this.searchField) {
			this.port = this.$route.params.port;
			this.olt_id = this.$route.params.olt_id;
			this.getOnuList();

			if (this.olt_id !== null) {
				this.getOlt();
			}
		}

		watch(
			() => this.$route,
			() => {
				this.onuList = null;
				this.port = null;
				this.olt_id = null;
				this.nt_id = null;

				this.searchField = this.$route.meta.searchField;

				if (!this.searchField) {
					this.port = this.$route.params.port;
					this.olt_id = this.$route.params.olt_id;

					if (!this.controller.signal.aborted) {
						this.controller.abort();
					}
					this.controller = new AbortController();

					this.getOnuList();
				}
			}
		);
	},
	unmounted() {
		if (!this.controller.signal.aborted) {
			this.controller.abort();
		}
	},
	methods: {
		filter() {
			let filterArray = [];

			if (this.olt_id) {
				filterArray.push("olt_id=" + this.olt_id);
			}
			if (this.port) {
				filterArray.push(this.port);
			}
			if (this.nt_id) {
				filterArray.push("nt_id__ilike=" + this.nt_id);
			}

			let filter = "";

			if (filterArray.length > 0) {
				filter = "?" + filterArray.join("&");
			}

			return filter;
		},

		getOnuList() {
			this.loading = true;
			this.onuList = null;
			axios
				.get(this.$root.$data.apiUrl + this.$root.$data.apiPath + "ntmetric/" + this.filter(), { signal: this.controller.signal })
				.then((response) => {
					this.onuList = [];

					response.data.objects.forEach(onu => {
						let o = { ...onu };

						const subscriptions = o['subscriptions'];

						if (o['subscriptions']) {
							delete o['subscriptions'];

							subscriptions.forEach(s => {
								if (s.type == "Internet") {
									o['internet_address'] = s.address;
									o['internet_subscriber'] = s.subscriber;
									if (s.location) {
										o['internet_longitude'] = s.location.longitude;
										o['internet_latitude'] = s.location.latitude;
									}
								} else if (s.type === "Tv") {
									o['tv_address'] = s.address;
									o['tv_subscriber'] = s.subscriber;
									if (s.location) {
										o['tv_longitude'] = s.location.longitude;
										o['tv_latitude'] = s.location.latitude;
									}
								} else if (s.type == "Telephone") {
									o['telephone_address'] = s.address;
									o['telephone_subscriber'] = s.subscriber;
									if (s.location) {
										o['telephone_longitude'] = s.location.longitude;
										o['telephone_latitude'] = s.location.latitude;
									}
								}

								if (s.address !== "" && o['address'] === null) {
									o['address'] = s.address;
								}
							});
						}

						this.onuList.push(o);
					});

					this.online = 0;
					this.offline = 0;
					this.unknown = 0;
					response.data.objects.forEach(onu => {
						if (onu.status === "online") {
							this.online++;
						} else if (onu.status === "offline") {
							this.offline++;
						} else if (onu.status === "unknown") {
							this.unknown++;
						}
					});

					let filenameArray = ["SparK"];

					if (this.$root.$data.selectedOlt && this.olt_id !== null) {
						filenameArray.push(this.$root.$data.selectedOlt.name);
					}

					if (this.searchField && this.nt_id) {
						filenameArray.push(this.nt_id);
					}

					filenameArray.push(moment().format("YYYY-MM-DD_hh-mm-ss"));

					this.filename = filenameArray.join("_");

					this.loading = false;

				})
				.catch((error) => {
					console.log(error);
					this.loading = false;
				});
		},

		selectOnu(onu) {
			this.selectedOnu = onu;
		},

		closeModal() {
			this.selectedOnu = null;
		},

		getOlt() {
			axios
				.get(this.$root.$data.apiUrl + this.$root.$data.apiPath + "olt/" + this.olt_id + "/")
				.then((response) => {
					this.$root.$data.selectedOlt = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},

		closeMap() {
			this.showMap = false;
		}
	},
};
</script>

<style scoped>
.full-width {
	width: 100%;
	max-width: 100%;
}
</style>
