<template>
	<Box>
		<template #title>
			Bejelentkezés
		</template>
		<template #content>
			<form v-on:keyup.enter="login">
				<div class="mb-1">
					<label class="form-label">Felhasználónév</label>
					<input type="text" class="form-control" v-model="this.username">
				</div>
				<div class="mb-4">
					<label class="form-label">Jelszó</label>
					<input type="password" class="form-control" v-model="this.password">
				</div>
				<div class="d-grid gap-2">
					<button type="button" class="btn btn-primary" @click="login">Bejelentkezés</button>
					<hr>
					<button type="button" class="btn btn-outline-warning btn-maverix" v-if="oauthEnable"
						@click="redirectOauth">Bejelentkezés Maverixben</button>
				</div>
			</form>
		</template>
	</Box>
</template>

<script>
import axios from "axios";
import { defineAsyncComponent } from "vue";

const Box = defineAsyncComponent(() => import("@/components/box.vue"));

export default {
	name: "LoginView",
	components: { Box },
	data: () => {
		return {
			username: null,
			password: null,

			oauthEnable: false,
			oauthEndpoint: null,
		};
	},
	mounted() {
		if (this.$root.$data.isLoggedin === true) {
			this.$router.push({ name: "Home" });
		}

		axios.get(this.$root.$data.apiUrl + "maverixauth?get=url").then((response) => {
			if (response.status === 200) {
				this.oauthEnable = true;
				this.oauthEndpoint = response.data;
			}
		});
	},
	methods: {
		login() {
			axios
				.post(this.$root.$data.apiUrl + this.$root.$data.apiPath + "session/", {
					username: this.username,
					password: this.password,
				})
				.then((response) => {
					if (response.status === 201) {
						axios
							.get(this.$root.$data.apiUrl + this.$root.$data.apiPath + "session/")
							.then((response) => {
								if (response.data.objects.length > 0) {
									this.$root.$data.isLoggedin = true;
									this.$root.$data.sessionId = response.data.objects[0].id;
									this.$router.push({ name: "Home" });
								} else {
									this.$root.$data.isLoggedin = false;
									this.$root.$data.sessionId = null;
									this.$router.push({ name: "Login" });
									this.$notification.create({ message: "Sikertelen bejelentkezés!", type: "error" }, document.querySelector(".notification-container"));
								}
							})
							.catch(() => {
								this.$root.$data.isLoggedin = false;
								this.$root.$data.sessionId = null;
								this.$router.push({ name: "Login" });
								this.$notification.create(
									{
										message: "Sikertelen bejelentkezés!",
										type: "error",
									},
									document.querySelector(".notification-container")
								);
							});
					}
				})
				.catch(() => {
					this.$root.$data.isLoggedin = false;
					this.$root.$data.sessionId = null;
					this.$router.push({ name: "Login" });
					this.$notification.create({ message: "Sikertelen bejelentkezés!", type: "error" }, document.querySelector(".notification-container"));
				});
		},
		redirectOauth() {
			if (this.oauthEnable) {
				window.location.href = this.oauthEndpoint;
			}
		},
	},
};
</script>

<style scoped>
@media screen and (max-width: 450px) {
	.container {
		background-color: transparent !important;
		border: none !important;
	}
}

.container {
	width: 350px;
}

input {
	font-size: 18px;
	padding: 6px 12px;
}
</style>
