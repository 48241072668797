import NotificationComponent from './Component.vue'
import { createComponent } from './helper.js';

export const useNotification = () => {
    return {
        create(options, selector) {
            createComponent(NotificationComponent, options, selector);
        }
    }
}
