<template>
	<div class="version">{{ this.$root.$data.uiVersion }}</div>

	<Sidebar v-if="this.isLoggedin" :darkMode="darkMode" :toggleDarkMode="toggleDarkMode"></Sidebar>
	<div class="main">
		<router-view />
	</div>
	<div class="notification-container">
	</div>
</template>
<script>
import axios from "axios";
import { defineAsyncComponent } from "vue";

import $ from "jquery";

import UIVersion from './version.js'

const Sidebar = defineAsyncComponent(() => import("@/components/sidebar.vue"));

export default {
	components: { Sidebar },
	data: () => {
		return {
			apiUrl: process.env.VUE_APP_API_URL,
			apiPath: process.env.VUE_APP_API_PATH,
			prometheusPath: process.env.VUE_APP_PROMETHEUS_PATH,

			uiVersion: UIVersion,

			isLoggedin: null,
			sessionId: null,

			selectedOlt: null,

			darkMode: null,
		};
	},
	created() {
		axios.defaults.withCredentials = true;

		axios.defaults.validateStatus = (status) => {
			return status < 500;
		};

		axios.interceptors.response.use((response) => {
			return response;
		}, (error) => {
			if (error.code === "ERR_CANCELED") {
				return;
			}

			if (error.response.status === 401) {
				this.isLoggedin = false;
				this.$router.push({ name: "Login" });
			}
		});

		axios
			.get(this.apiUrl + this.apiPath + "session/")
			.then((response) => {
				if (response.data.objects.length > 0) {
					this.isLoggedin = true;
					this.sessionId = response.data.objects[0].id;

					if (this.$router.currentRoute.value.name === "Login") {
						this.$router.push({ name: "Home" });
					}
				} else {
					this.isLoggedin = false;
					this.$router.push({ name: "Login" });
				}
			})
			.catch(() => {
				this.isLoggedin = false;
				this.$router.push({ name: "Login" });
			});
	},
	mounted() {
		let darkMode = localStorage.getItem("darkmode");

		if (darkMode === "true") {
			this.darkMode = true;
		} else if (darkMode === "false") {
			this.darkMode = false;
		}

		$("html").attr("data-bs-theme", (this.darkMode ? 'dark' : 'light'));
	},
	methods: {
		toggleDarkMode() {
			this.darkMode = !this.darkMode;

			localStorage.setItem("darkmode", this.darkMode);

			$("html").attr("data-bs-theme", (this.darkMode ? 'dark' : 'light'));
		}
	}
};
</script>

<style>
@media screen and (max-width: 850px) {
	.main {
		padding: 0px !important;
		padding-top: 40px !important;
	}

	.sidebar+.main {
		margin-left: 0 !important;
	}

	.notification-container {
		width: 100% !important;
		bottom: unset !important;
		top: 5%;
	}
}

html,
body {
	width: 100%;
	height: 100%;
}

#app {
	height: 100%;
	z-index: 1;
}

.main {
	padding: 20px;
	transition: 0.35s;
}

.sidebar.toggle+.main {
	margin-left: 260px;
}

.sidebar:not(.toggle)+.main {
	margin-left: 0 !important;
}

.notification-container {
	width: 450px;
	position: absolute;
	right: 0;
	bottom: 5%;
	overflow: auto;
	z-index: 9930;
}

.version {
	position: absolute;
	font-size: 12px;
	color: var(--bs-gray);
}
</style>
