import NotificationComponent from './Component.vue'
import { useNotification } from "./api"

const NotificationPlugin = {
    install: (app, options = {}) => {
        let instance = useNotification(options);
        app.config.globalProperties.$notification = instance
        app.provide('$notification', instance)
    }
}

export default NotificationPlugin;
export { useNotification, NotificationPlugin, NotificationComponent }
