import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import NotificationPlugin from './components/notification/index'
import ConfirmDialogPlugin from './components/confirm_dialog/index'

import '@popperjs/core'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import 'bootstrap-icons/font/bootstrap-icons.css'
import 'floating-vue/dist/style.css'

import {
    VTooltip,
    VClosePopper,
    Dropdown,
    Tooltip,
    Menu
} from 'floating-vue'

const app = createApp(App);

app.use(router);
app.use(NotificationPlugin);
app.use(ConfirmDialogPlugin);


app.directive('tooltip', VTooltip)
app.directive('close-popper', VClosePopper)

app.component('VDropdown', Dropdown)
app.component('VTooltip', Tooltip)
app.component('VMenu', Menu)

const vm = app.mount('#app')

router.beforeEach((to, from, next) => {
    if (to.name !== 'Login' && vm.isLoggedin === false) next({ name: 'Login' })
    else next()
});
