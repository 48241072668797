import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import OnuList from '../views/OnuList.vue'
import OltList from '../views/OltList.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: OltList
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/onulist/:olt_id',
		name: 'OltOnuList',
		component: OnuList,
		meta: {
			searchField: false
		}
	},
	{
		path: '/onulist/:olt_id&:port',
		name: 'InterfOnuList',
		component: OnuList,
		meta: {
			searchField: false
		}
	},
	{
		path: '/onulist',
		name: 'OnuList',
		component: OnuList,
		meta: {
			searchField: true
		}
	},
	{
		path: '/oltlist',
		name: 'OltList',
		component: OltList
	},

]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	linkActiveClass: "active"
})

export default router
