import { h, render } from 'vue';

export function removeElement(el) {
	el.parentNode.remove()
}

export function createComponent(component, props, parentContainer, slots = {}) {
	const vNode = h(component, props, slots);
	const container = document.createElement('div');
	parentContainer.appendChild(container);
	render(vNode, container);

	return vNode.component
}
